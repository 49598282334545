import * as CustomConstants from './custom';
import * as AppActions from '../AppActions';
import {getOutlet} from 'reconnect.js';

const CAT_INITIAL_DATA = {
  categories: [],
  meta_data: {},
};

const THEME_COLOR = '#C3AAD1';
const THEME_COLOR_SECOND = '#B1C1EE';

const SITE_INFO = {
  title: 'Pokémon Center',
  subtitle: 'Dashboard',
  icon: '/images/favicon.png',
  address: '103 台北市大同區民權西路136號10樓之5',
};

const SITE_CONFIG = {
  config: {display: '設定', value: 'config'},
  landing: {
    display: '首頁設定',
    value: 'landing',
  },
  about: {display: '關於我們', value: 'about'},
  navbar_config: {display: '頁眉設定', value: 'navbar_config'},
  footer_config: {display: '頁尾設定', value: 'footer_config'},
  product_category: {
    display: '商品分類',
    value: 'product_category',
    initial_data: CAT_INITIAL_DATA,
  },
  article_category: {
    display: '文章分類',
    value: 'article_category',
    initial_data: CAT_INITIAL_DATA,
  },
  sales_event_category: {
    display: '銷售活動',
    value: 'sales_event_category',
    initial_data: CAT_INITIAL_DATA,
  },
  customer_support_config: {
    display: '客戶諮詢表單設定',
    value: 'customer_support_config',
  },
};

const SPINNER_CONFIG = {
  image: '/images/site-logo-spinner.png',
};

const EMPTY_CONFIG = {
  image: '/images/site-logo.png',
  title: '敬請期待',
  subtitle: '此頁面目前沒有資料',
  buttonText: '前往首頁',
  onButtonClick: async () => await AppActions.navigate('/'),
};

const LOGIN_REQUIRED_CONFIG = {
  image: '/images/site-logo.png',
  title: '尚未登入',
  subtitle: '此頁面需要登入，方能瀏覽',
  buttonText: '登入',
  onButtonClick: ({admin}) => {
    const loginModal = getOutlet('login-modal');
    loginModal.update({admin});
  },
};

const constants = {
  THEME_COLOR,
  THEME_COLOR_SECOND,
  SITE_INFO,
  SITE_CONFIG,
  SPINNER_CONFIG,
  EMPTY_CONFIG,
  LOGIN_REQUIRED_CONFIG,
  ...CustomConstants,
};

export default constants;
