import React from 'react';
import {Helmet} from 'react-helmet';
import {getOutlet} from 'reconnect.js';
import Config from '../../../data.json';
import constants from '../../constants';
import NftMintSection from '../../Components/SnowOwl/NftMintSection';

export function renderHtmlHelmet(props) {
  const {location} = props;
  const {SITE_INFO} = constants;

  if (location.pathname.indexOf('admin') !== -1) {
    return (
      <Helmet>
        <title>{SITE_INFO.title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.min.js"
          integrity="sha512-IM60GPudO4jk+ZQm3UlJgKHhXQi5pNDM6mP+pLKL968YgkHMc7He3aGJOVHEZ9rJ4vAaEtJ8W6SKa7Qq4inzBA=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <script src="https://cdn.jsdelivr.net/jsbarcode/3.3.20/JsBarcode.all.min.js" />
        <script src="https://cdn.jsdelivr.net/npm/davidshimjs-qrcodejs@0.0.2/qrcode.min.js" />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{SITE_INFO.title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${Config.gaId}`}
      />
      <script
        src={`https://www.paypal.com/sdk/js?client-id=${Config.paypalClientId}&currency=TWD`}
      />
    </Helmet>
  );
}

export function getAdminRoutes() {
  const config = getOutlet('config');

  return [
    {name: '首頁', path: '/admin/'},
    {name: '會員', path: '/admin/users/'},
    {name: '作家', path: '/admin/artists/'},
    {
      name: '商品',
      path: '__group-product',
      subroutes: [
        {name: '商品全覽', path: '/admin/products/'},
        {name: '庫存管理', path: '/admin/stocks/'},
        {name: '庫存管理紀錄', path: '/admin/stock-records'},
        {name: '封存商品', path: '/admin/archived_products'},
        {name: '多語系(Beta)', path: '/admin/i18n-products/'},
      ],
    },
    {
      name: '訂單',
      path: '__group-order',
      subroutes: [
        {name: '訂單全覽', path: '/admin/orders/'},
        {name: '批次處理', path: '/admin/orders/bulk-write'},
        {name: '待處理', path: '/admin/orders/waiting/'},
        {name: '處理中', path: '/admin/orders/processing/'},
        {name: '已完成', path: '/admin/orders/done/'},
        {name: '已取消', path: '/admin/orders/canceled/'},
        {name: '未結單', path: '/admin/orders/checkout/'},
        {name: '退貨紀錄', path: '/admin/orders/return/'},
        {name: '建立客製化訂單', path: 'admin-custom-order'},
      ],
    },
    {
      name: '優惠',
      path: '__group-discount',
      subroutes: [
        {name: '優惠券', path: '/admin/coupons/'},
        {name: '滿額折扣', path: '/admin/discount-list/'},
        {name: '組合折扣', path: ''},
      ],
    },
    {
      name: '文章',
      path: '__group-article',
      subroutes: [
        {name: '文章全覽', path: '/admin/articles/'},
        {name: '多語系(Beta)', path: '/admin/i18n-articles'},
      ],
    },
    {name: '常見問題', path: '/admin/faq'},
    {name: '網站設定', path: '/admin/site/'},
    Config.allowGithubRebuild
      ? {name: '前台手動更新', path: '/admin/rebuild-history'}
      : null,
    {
      name: '上傳工具',
      path: '__group-uploader',
      subroutes: [
        {name: '圖片上傳', path: 'image-uploader'},
        {name: '檔案上傳', path: 'file-uploader'},
      ],
    },
    {name: '客戶諮詢', path: '/admin/customer-support'},
    {name: '重設密碼', path: 'reset-password'},
    {name: '登出', path: 'logout'},
  ]
    .filter((_) => !!_)
    .map((route) => {
      if (route.name === '優惠' && !!config.getValue()) {
        let combine_discount_index = route.subroutes.findIndex(
          (subroute) => subroute.name === '組合折扣',
        );

        route.subroutes[
          combine_discount_index
        ].path = `/admin/combine-discount/?action=detail&id=${
          config.getValue()._id.$oid
        }`;
      }

      return route;
    });
}

export function renderProfileCustomSection(sectionId, activePath, extraData) {
  let {action, location, order_id, order_number} = extraData;

  if (sectionId === 'E') {
    if (location.pathname.indexOf('/order') > -1 && action === 'detail') {
      if (!!order_id || !!order_number) {
        return <NftMintSection id={order_id || order_number} />;
      }
    }
  }

  return null;
}
