import React from 'react';
import styled from 'styled-components';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import NavLink from '../NavLink';
import constants from '../../constants';
import * as AppActions from '../../AppActions';
import {Cart} from '@styled-icons/bootstrap/Cart';
import {PersonCircle} from '@styled-icons/bootstrap/PersonCircle';
import {Menu as MenuIcon} from '@styled-icons/boxicons-regular/Menu';
import * as NavUtil from '../../Utils/NavUtil';
import * as Locale from '../../Locale';

export function NavItemsContent(props) {
  const {navItems, rwd, showLoginModal, setMobileNavVisible} = props;
  const [user] = useOutlet('user');
  const [cart] = useOutlet('cart');

  if (rwd) {
    return (
      <>
        <div style={{flex: 1}} />
        {user ? (
          <>
            <button
              className="nav-round-btn-box"
              onClick={async () => {
                await AppActions.navigate('/checkout', {loading: 500});
              }}>
              <Cart size={20} />
              {cart?.items && <div>({cart.items.length})</div>}
            </button>

            {!user.tmp ? (
              <button
                className="nav-round-btn-box"
                style={{backgroundColor: 'transparent'}}
                onClick={async () => {
                  await AppActions.navigate('/profile', {loading: 500});
                }}>
                <PersonCircle size={20} color={constants.THEME_COLOR_SECOND} />
              </button>
            ) : (
              <button
                className="nav-round-btn-box"
                onClick={() => showLoginModal(true)}>
                <PersonCircle size={20} color={'white'} />
                <div>{Locale.getLabel('login')}</div>
              </button>
            )}
          </>
        ) : (
          <button
            className="nav-round-btn-box"
            onClick={() => showLoginModal(true)}>
            <PersonCircle size={20} color={'white'} />
            <div>{Locale.getLabel('login')}</div>
          </button>
        )}
        <div className="menu-btn-box" onClick={() => setMobileNavVisible(true)}>
          <MenuIcon size={20} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="nav-items">
        {navItems.map((navItem, idx) => {
          const selected = NavUtil.isNavLinkSelected(navItem);

          if (navItem.entries) {
            return (
              <DropDownMenu key={idx}>
                <button className="dropdown-btn" key={idx}>
                  {Locale.getField(navItem, 'name')}
                </button>

                <div className="dropdown-content">
                  {(navItem.entries || []).map((item, i) => (
                    <NavLink
                      {...NavUtil.getNavLinkExtraProps(navItem)}
                      to={item.path}
                      key={i}>
                      {Locale.getField(item, 'name')}
                    </NavLink>
                  ))}
                </div>
              </DropDownMenu>
            );
          }

          return (
            <NavLink
              {...NavUtil.getNavLinkExtraProps(navItem)}
              to={navItem.path}
              key={idx}
              className={selected ? `default selected` : `default`}>
              {Locale.getField(navItem, 'name')}
            </NavLink>
          );
        })}
      </div>

      {user ? (
        <>
          <button
            className="nav-round-btn-box"
            onClick={async () => {
              await AppActions.navigate('/checkout', {loading: 500});
            }}>
            <Cart size={22} />
            <div>購物車</div>
            {cart?.items && <div>({cart.items.length})</div>}
          </button>

          {!user.tmp ? (
            <button
              className="nav-icon-btn-box"
              style={{marginLeft: 6}}
              onClick={async () => {
                await AppActions.navigate('/profile', {loading: 500});
              }}>
              <PersonCircle size={22} color={constants.THEME_COLOR_SECOND} />
            </button>
          ) : (
            <button
              className="nav-round-btn-box"
              onClick={() => {
                showLoginModal(true);
              }}>
              <PersonCircle size={22} color={'white'} />
              <div>{Locale.getLabel('login')}</div>
            </button>
          )}
        </>
      ) : (
        <button
          className="nav-round-btn-box"
          onClick={() => {
            showLoginModal(true);
          }}>
          <PersonCircle size={26} color={'white'} />
          <div>{Locale.getLabel('login')}</div>
        </button>
      )}
    </>
  );
}

const DropDownMenu = styled.div`
  position: relative;
  display: inline-block;

  & > .dropdown-btn {
    border: none;
    background-color: #ffffff;
    padding: 0px 10px;
    cursor: pointer;

    border-bottom: 6px solid transparent;
    margin: 0px 25px;
    color: #707070;
  }

  & > .dropdown-content {
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 101;
    background-color: #ffffff;
    min-width: 100px;
    overflow: hidden;

    transition: all 150ms linear 100ms;
    height: 0px;
    opacity: 0;

    & > a {
      display: block;
      line-height: 2.2;
      text-align: center;
    }
  }

  &:hover > .dropdown-btn {
    color: var(--secondColor);
  }

  &:hover > .dropdown-content {
    height: auto;
    padding: 15px 5px;
    opacity: 1;

    & > a:hover {
      color: var(--secondColor);
    }
  }
`;
