import React from 'react';
import styled from 'styled-components';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import * as Ant from 'antd';
import NavLink from '../NavLink';
import constants from '../../constants';
import MobileNavDrawer from './MobileNavDrawer';
import * as NavUtil from '../../Utils/NavUtil';
import {NavItemsContent} from './NavItemsContent';
import * as Locale from '../../Locale';
import useGlobalOutlets from '../../Hooks/useGlobalOutlets';

function SiteNavBar(props) {
  useGlobalOutlets();

  const {location} = props;
  const [dimension] = useOutlet('dimension');
  useOutlet('navbarConfig');
  const showLoginModal = useOutletSetter('login-modal');
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);
  const navItems = NavUtil.getNavItems(props);
  const rwd =
    dimension.rwd !== 'desktop' ||
    (navItems.length >= 5 && dimension.innerWidth <= 1150);

  React.useEffect(() => {
    setMobileNavVisible(false);
  }, [location?.pathname]);

  const mobileNavItemContent = (
    <div className="nav-items">
      {navItems.map((navItem, idx) => {
        const selected = NavUtil.isNavLinkSelected(navItem);

        return (
          <React.Fragment key={idx}>
            <NavLink
              {...NavUtil.getNavLinkExtraProps(navItem)}
              to={navItem.path}
              className={selected ? `default selected ` : `default`}>
              {Locale.getField(navItem, 'name')}
            </NavLink>
            {(navItem.entries || []).map((item, i) => (
              <NavLink
                {...NavUtil.getNavLinkExtraProps(item)}
                to={item.path}
                key={`item-${i}`}
                className={`default-child`}>
                {Locale.getField(item, 'name')}
              </NavLink>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );

  return (
    <Wrapper id="rev-SiteNavBar">
      <div className="maxwidth-container">
        <div className="logo-box">
          <NavLink to="/" loading={800}>
            <img
              src="/images/site-logo.png"
              alt="Logo"
              style={{
                height: 40,
                objectFit: 'contain',
              }}
            />
          </NavLink>
        </div>

        <NavItemsContent
          rwd={rwd}
          navItems={navItems}
          showLoginModal={showLoginModal}
          setMobileNavVisible={setMobileNavVisible}
        />
      </div>

      <MobileNavDrawer
        visible={rwd && mobileNavVisible}
        setVisible={setMobileNavVisible}
        {...props}>
        {mobileNavItemContent}
      </MobileNavDrawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: var(--topNavBarHeight);
  padding: 0px 40px;

  display: flex;
  align-items: center;

  z-index: 101;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: 200ms;

  &:hover {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.16);
  }

  & > .maxwidth-container {
    display: flex;
    align-items: center;
    max-width: var(--contentMaxWidth);
    width: 100%;
    margin: 0 auto;
  }

  & > .maxwidth-container > .logo-box {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  & > .maxwidth-container > .nav-items {
    flex: 1;
    display: flex;
    align-items: center;

    & > * {
      &:hover {
        color: var(--primaryColor);
      }

      &.default {
        border-bottom: 6px solid transparent;
        margin: 0px 25px;
        padding: 19px 5px;
        color: #707070;
      }

      &.selected {
        border-bottom: 6px solid var(--primaryColor);
        color: #242424;
      }
    }
  }

  & > .maxwidth-container > .menu-btn-box {
    padding: 8px;
    cursor: pointer;
  }

  & .nav-round-btn-box {
    display: flex;
    align-items: center;
    border-radius: 60px;
    padding: 6px;
    background-color: ${constants.THEME_COLOR};
    color: white;
    outline: none;
    border: none;
    box-shadow: none;
    transition: all 200ms;
    cursor: pointer;
    margin-left: 6px;

    & > * {
      margin: 0 3px;
    }
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }
    &:active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  }

  & .nav-icon-btn-box {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }
    &:active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  }

  @media (max-width: 768px) {
    padding: 16px;

    & .nav-round-btn-box {
      justify-content: center;
    }
  }
`;

export default SiteNavBar;
