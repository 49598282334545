import {css} from 'styled-components';
import constants from '../constants';

export default css`
  --contentMaxWidth: 1200px;
  --contentMinHeight: 840px;
  --topNavBarHeight: 64px;
  --basePadding: 15px 20px;
  --primaryColor: ${constants.THEME_COLOR};
  --secondColor: ${constants.THEME_COLOR_SECOND};

  min-height: 100vh;

  #rev-ProductListPage,
  #rev-ProductDetailPage {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding-bottom: 20px;

    & .rev-FilterMenuL2 {
      z-index: 99;
    }

    & .rev-FilterMenuL2.mobile {
      padding-top: var(--topNavBarHeight);
    }

    & .rev-mobile-menu-btn {
      & > button {
        display: none;
      }

      &::before {
        display: block;
        content: '產品列表';
        padding: 8px 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: var(--primaryColor);
        color: white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
    }
  }

  #rev-ArticleListPage {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding-bottom: 20px;

    & .rev-FilterMenuTop {
      background-color: #ffffff;
      z-index: 99;

      & > .header {
        & > .label {
          display: none;
        }

        &::before {
          display: block;
          content: '';
          flex: 1;
          font-size: 1.3rem;
          cursor: pointer;
          text-align: center;
        }
      }
    }

    & .rev-mobile-menu-btn {
      & > button {
        display: none;
      }

      &::before {
        display: block;
        content: '文章列表';
        padding: 8px 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: var(--primaryColor);
        color: white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
    }
  }

  #rev-CartPage {
    #checkout-btn {
      background-color: var(--primaryColor);
    }
  }

  #rev-CheckoutInfoPage {
    #checkout-btn {
      background-color: var(--primaryColor);
    }
  }

  #rev-CheckoutReviewPage {
    #checkout-btn {
      background-color: var(--primaryColor);
    }
  }

  #rev-OrderPage {
  }

  #rev-ArticleDetailPage {
    & > .content > .categories > ul.ant-menu {
      position: sticky;
      top: calc(var(--topNavBarHeight) + 20px);
    }
  }

  #rev-UnderMaintainPage,
  #rev-NotFoundPage {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    width: 100vw;
    height: 100vh;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    & > .logo-box {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }

  .window-container {
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
    background-color: #f4f4f4;
    border-radius: 20px;

    & > .header {
      border-bottom: 5px solid #ffffff;
      padding: 20px;
      display: flex;

      & > div {
        margin-right: 24px;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: #ffffff;
      }
    }

    & > .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 20px;

      & > img {
        margin-bottom: 8px;
        width: 220px;
        height: 194px;
      }

      & > h1 {
        margin-bottom: 5px;
        text-align: center;
        color: #85b5fd;
      }

      & > h2 {
        margin-bottom: 23px;
        text-align: center;
        color: #85b5fd;
      }

      & > p {
        margin-bottom: 30px;
        text-align: center;
        color: #707070;
      }

      & > a > button {
        cursor: pointer;
        width: 180px;
        border-radius: 23px;
        border: 0px;
        padding: 9px;
        background-color: var(--primaryColor);
        color: #ffffff;

        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }
        &:active {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
`;
