import React from 'react';
import styled from 'styled-components';
import {Input, Button, Card, Spin, message} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActionsEx from '../../AppActions/custom';

function NftMintSection(props) {
  const {id} = props;
  const [addr, setAddr] = React.useState('');
  const [instance, setInstance] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const getAddrInputProps = () => {
    return {
      value: addr,
      onChange: (e) => setAddr(e.target.value),
    };
  };

  const fetchRecordById = async (id) => {
    try {
      setLoading(true);
      let resp = await JStorage.fetchOneDocument('order', {
        $or: [{order_id: id}, {order_number: id}],
      });
      setInstance(resp);
    } catch (err) {
      console.warn('fetch order instance failed', err);
    } finally {
      setLoading(false);
    }
  };

  const postNftMint = async ({id, addr}) => {
    if (!addr) {
      message.warning('請填寫地址，才能 mint nft。');
      return;
    }

    setLoading(true);
    try {
      await AppActionsEx.postNftMint({id, addr});
      message.success('送出成功！');
    } catch (err) {
      console.warn('post nft mint failed', err);
      message.error('發生錯誤，請稍後再試！');
    } finally {
      await fetchRecordById(id);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchRecordById(id);
  }, [id]);

  if (instance && instance.payment_status === 'success') {
    if (instance.nft_status === '') {
      return (
        <Wrapper>
          <FormField>
            <label>MetaMask 錢包地址</label>
            <Input {...getAddrInputProps()} />
            <Button type="primary" onClick={() => postNftMint({id, addr})}>
              Mint NFT
            </Button>
          </FormField>
        </Wrapper>
      );
    } else if (instance.nft_status === 'failure') {
      return (
        <Wrapper>
          <FormField>
            <label>MetaMask 錢包地址</label>
            <Input {...getAddrInputProps()} />
            <Button type="primary" onClick={() => postNftMint({id, addr})}>
              Mint NFT
            </Button>
          </FormField>
          <Card
            size="small"
            title="Mint Information"
            extra={
              <Button
                shape="circle"
                icon={<ReloadOutlined />}
                onClick={() => fetchRecordById(id)}
              />
            }>
            {loading ? (
              <Spin size="small" />
            ) : (
              <>
                <p>錢包地址：{instance.extra_data.nft_addr}</p>
                <p>目前狀態：{instance.nft_status}</p>
                <p>token id: {instance.extra_data.nft_token_id}</p>
                <p>tx: {instance.extra_data.nft_tx}</p>
                <p>message：{instance.extra_data.nft_message}</p>
              </>
            )}
          </Card>
        </Wrapper>
      );
    } else if (instance.nft_status === 'waiting') {
      return (
        <Wrapper>
          <Card
            size="small"
            title="Mint Information"
            extra={
              <Button
                shape="circle"
                icon={<ReloadOutlined />}
                onClick={() => fetchRecordById(id)}
              />
            }>
            {loading ? (
              <Spin size="small" />
            ) : (
              <>
                <p>錢包地址：{instance.extra_data.nft_addr}</p>
                <p>目前狀態：{instance.nft_status}</p>
                <p>token id: {instance.extra_data.nft_token_id}</p>
                <p>tx: {instance.extra_data.nft_tx}</p>
                <p>message：{instance.extra_data.nft_message}</p>
              </>
            )}
          </Card>
        </Wrapper>
      );
    } else if (instance.nft_status === 'success') {
      return (
        <Wrapper>
          <Card
            size="small"
            title="Mint Information"
            extra={
              <Button
                shape="circle"
                icon={<ReloadOutlined />}
                onClick={() => fetchRecordById(id)}
              />
            }>
            {loading ? (
              <Spin size="small" />
            ) : (
              <>
                <p>錢包地址：{instance.extra_data.nft_addr}</p>
                <p>目前狀態：{instance.nft_status}</p>
                <p>token id: {instance.extra_data.nft_token_id}</p>
                <p>tx: {instance.extra_data.nft_tx}</p>
                <p>message：{instance.extra_data.nft_message}</p>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button
                    ghost
                    type="link"
                    target="_blank"
                    href={`https://opensea.io/assets/matic/0x9dc6e197648b1a127ef9a2934f667bd448bf1c9a/
${instance.extra_data.nft_token_id}`}>
                    Look OpenSea
                  </Button>
                  <Button
                    ghost
                    type="link"
                    target="_blank"
                    href={`https://polygonscan.com/tx/${instance.extra_data.nft_tx}`}>
                    Transaction Details
                  </Button>
                </div>
              </>
            )}
          </Card>
        </Wrapper>
      );
    }
  }

  return null;
}

const Wrapper = styled.div`
  margin-bottom: 25px;
  padding: 15px 20px;
  color: #ffffff;
`;

const FormField = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px 15px;

  & > label {
    width: 100px;
    margin-right: 10px;
  }
`;

export default NftMintSection;
