import {css} from 'styled-components';
import {THEME_COLOR} from '../constants';

const DARK_THEME = css`
  --snow-owl-theme: var(--snow-owl-dark-16);
  --snow-owl-theme-font-color: var(--snow-owl-light-16);
  --snow-owl-theme-button-hover-color: var(--snow-owl-blue-16);
  --snow-owl-theme-button-border-color: #222;
`;

const LIGHT_THEME = css`
  --snow-owl-theme: var(--snow-owl-light-16);
  --snow-owl-theme-font-color: var(--snow-owl-dark-16);
  --snow-owl-theme-button-hover-color: var(--snow-owl-blue-16);
  --snow-owl-theme-button-border-color: #ddd;
`;

export default css`
  --contentMaxWidth: 1200px;
  --contentMinHeight: 840px;
  --topNavBarHeight: 64px;
  --bottomSiteFooterHeight: 127px;
  --basePadding: 15px 20px;
  --primaryColor: ${THEME_COLOR};
  --sectionPadding: 50px 100px;
  --sectionMobilePadding: 20px;
  overflow-x: hidden;

  --snow-owl-dark-16: #121619ff;
  --snow-owl-light-16: #ffffffff;
  --snow-owl-blue-16: #0c8ef2ff;
  --snow-owl-gary-16: #343a3fff;
  --snow-owl-red-16: #8b0000ff;

  & .ant-layout {
    background-color: var(--snow-owl-theme) !important;
  }

  & .ant-layout-sider-children {
    & header {
      background-color: var(--snow-owl-theme) !important;
    }
  }

  & .ant-descriptions-item-content {
    svg {
      color: white;
    }
  }

  &
    #gatsby-focus-wrapper
    > section
    > section
    > div
    > div:nth-child(2)
    > div
    > div {
    background-color: transparent !important;
  }

  #rev-GlobalSpinner {
    background-color: transparent;
    box-shadow: none;
  }

  #rev-admin-layout {
    & .rev-CartList {
      background-color: var(--snow-owl-theme);
    }
  }

  /* #rev-GlobalSpinner {
  } */

  #rev-ProductListPage .rev-FilterMenuL2,
  #rev-ProductDetailPage .rev-FilterMenuL2 {
    position: sticky;
    top: 72px;
    margin: 0px 10px;

    & > .header {
      padding: 23px 0px;
      font-size: 1.25rem;
      color: #ffffff;

      & > .label {
        display: none;
      }

      &::before {
        content: '產品列表';
        flex: 1;
        font-size: 1.3rem;
        color: #ffffff;
      }
    }

    & > .L1 {
      background-color: transparent;
      border-radius: 0px;
      padding: 0px;
      margin-bottom: 0px;
      box-shadow: none;

      & > .header {
        padding: 10px 0;
        border-top: 1px solid #d0d0d0;
        font-size: 1rem;
        color: #ffffff;
      }

      & > .L1-items {
        border-top: 1px solid #d0d0d0;
        padding-left: 15px;
        overflow: hidden;

        & > .L2 {
          padding: 5px 10px;
        }

        & > .L2 > .header {
          font-size: 0.95rem;
          color: #6f6f6f;
        }

        & > .L2.selected > .header {
          font-size: 0.95rem;
          color: var(--primaryColor);
        }
      }

      &:last-child {
        border-bottom: 1px solid #d0d0d0;
      }
    }
  }

  #rev-ArticleListPage .rev-FilterMenuL1 {
    position: sticky;
    top: 72px;
    margin-left: 10px;

    &.mobile {
      margin: 0px;
    }

    & > .header {
      & > .label {
        display: none;
      }

      &::before {
        content: '文章列表';
        flex: 1;
        font-size: 1.3rem;
        text-align: center;
      }
    }
  }

  & * {
    transition: background-color 1000ms ease;
  }
  ${({theme}) => (theme === 'light' ? LIGHT_THEME : DARK_THEME)};

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .title {
    font-size: ${(props) => (props.rwd === 'desktop' ? 22 : 15)}px;
    color: var(--primaryColor);
    font-size: 22px;
    letter-spacing: 2px;
    font-weight: bold;
  }
  /* 
  .row {
    display: flex;
    flex-direction: row;
  } */

  .subtitle {
    color: var(--primaryColor);
    font-size: ${(props) => (props.rwd === 'desktop' ? 13 : 10)}px;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .constrain-content {
    max-width: 900px;
    margin: 0 auto;
  }

  .section-dark {
    padding: ${(props) =>
      props.rwd === 'desktop'
        ? 'var(--sectionPadding)'
        : 'var(--sectionMobilePadding)'};
    background-color: #f9f8f7;
  }

  .section {
    padding: ${(props) =>
      props.rwd === 'desktop'
        ? 'var(--sectionPadding)'
        : 'var(--sectionMobilePadding)'};
  }

  .carousel-wrapper {
    display: flex;
    flex-direction: ${(props) => (props.rwd === 'desktop' ? 'row' : 'column')};
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .content {
    font-size: ${(props) => (props.rwd === 'desktop' ? 14 : 10)}px;
    letter-spacing: 2px;
    line-height: 22px;
    white-space: break-spaces;
    color: #000;
  }

  .centered {
    margin: 0 auto;
    text-align: center;
  }

  #rev-checkout-layout {
    background-color: var(--snow-owl-theme);
  }

  #rev-SiteFooter {
    background-color: var(--snow-owl-theme);

    & .contact-items > .title {
      color: var(--snow-owl-theme-font-color);
    }
  }

  #rev-SiteNavBar {
    background-color: var(--snow-owl-theme);

    & > .maxwidth-container > .nav-items > a {
      color: #ffffff !important;
    }
  }

  #rev-ArticleListPage {
    & > .content > div {
      background-color: var(--snow-owl-theme);
      color: #ffffff !important;
    }

    & > .content > div > .header {
      background-color: var(--snow-owl-theme);
    }

    & > .content > div > .items {
      background-color: var(--snow-owl-theme);

      & > * {
        background-color: var(--snow-owl-theme);
      }
    }
  }

  #rev-ArticleDetailPage {
    background-color: var(--snow-owl-theme);
    & > .content {
      color: #ffffff;
    }
  }

  #rev-CartPage {
    & > .container > .main > div {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .main * .product-variants * button {
      background-color: var(--snow-owl-theme);
    }

    & > .container > .side > div > .detail-info {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .side > div > #checkout-btn {
      background-color: var(--primaryColor);
    }
  }

  #rev-CheckoutInfoPage {
    & > .container > .main > div > .ant-form > div > .header {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .main > div > .ant-form > div > .content {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .side > div > .detail-info {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .side > div > #checkout-btn {
      background-color: var(--primaryColor);
    }
  }

  #rev-CheckoutReviewPage {
    & > .container > .main > div {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .main * .product-variants * button {
      background-color: var(--snow-owl-theme);
    }

    & > .container > .main > div > .ant-form > div > .header,
    .content {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .main > div > .ant-form > div > .content {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .side > div > .detail-info {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > .container > .side > div > .detail-info > .discounts-info {
      & .ant-card-body > div > h4 {
        color: #ffffff;
      }

      & .ant-alert-warning > .ant-alert-content > .ant-alert-message {
        color: var(--snow-owl-dark-16);
      }
    }

    & > .container > .side > div > #checkout-btn {
      background-color: var(--primaryColor);
    }
  }

  #rev-OrderPage {
    color: #ffffff;

    & > div > div > div > .header,
    .content {
      background-color: var(--snow-owl-theme);
      color: #ffffff;
    }

    & > div > div > div > .content > div {
      background-color: var(--snow-owl-theme);
    }
  }

  .window-container {
    border: 1px solid #ffffff;
  }
`;
