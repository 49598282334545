import {getOutlet} from 'reconnect.js';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import Config from '../../data.json';

const req = ApiUtil.req;
const UserOutlet = getOutlet('user');

const AppActionsOverride = {};

//////////////////////////////////////////////////
// Override existing AppActions
AppActionsOverride.doCustomThing = async (...args) => {
  console.log(args);
};

export default AppActionsOverride;

//////////////////////////////////////////////////
// Extra custom actions
function sayHello() {
  console.log('hello, this is custom section, custom it');
}

function postNftMint({order_id, addr}) {
  return req(
    `${Config.apiHost}/nft/mint?token=${UserOutlet.getValue().token}`,
    {
      method: 'post',
      data: {
        order_id,
        addr,
      },
    },
  );
}

export {sayHello, postNftMint};
