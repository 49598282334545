import {getOutlet} from 'reconnect.js';
import * as AppActions from '../AppActions';

const TEST_CONSTANTS = {
  test: 'test',
};

const SITE_INFO = {
  title: 'Snowy Owl',
  subtitle: 'Dashboard',
  icon: '/images/site-logo.png',
  address: '',
};

const SPINNER_CONFIG = {
  image: '/images/site-logo.png',
};

const EMPTY_CONFIG = {
  image: '/images/site-logo.png',
  title: '敬請期待',
  subtitle: '此頁面目前沒有資料',
  buttonText: '前往首頁',
  onButtonClick: async () => await AppActions.navigate('/'),
};

const LOGIN_REQUIRED_CONFIG = {
  image: '/images/site-logo.png',
  title: '尚未登入',
  subtitle: '此頁面需要登入，方能瀏覽',
  buttonText: '登入',
  onButtonClick: ({admin}) => {
    const loginModal = getOutlet('login-modal');
    loginModal.update({admin});
  },
};

const THEME_COLOR = '#0C8EF2';

export {
  TEST_CONSTANTS,
  SITE_INFO,
  SPINNER_CONFIG,
  THEME_COLOR,
  EMPTY_CONFIG,
  LOGIN_REQUIRED_CONFIG,
};
